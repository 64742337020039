import {QualificationCard, QualificationType, TimePeriod} from '../../enums/qualifications';
import {
  QualificationAssignmentDoc,
  QualificationAssignmentRequirementEvent,
  QualificationAssignmentRequirementEventStatus,
  QualificationAssignmentStatus,
  QualificationDoc,
  QualificationRefreshPeriod,
} from '../../dto/qualifications';
import {UserDocument} from '../../dto/userDoc';
import {convertTimestamp} from '../../utils/dates/convertTimestamp';
import {QueryDocumentSnapshot} from '@angular/fire/compat/firestore';
import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import Timestamp = firebase.firestore.Timestamp;
import { QualificationTimeline } from './QualificationTimeline';

interface QualificationAssignmentOptions {
  withQualification?: QualificationDoc,
  shouldRecalculateDates?: boolean,
  simulatedNowDate?: Date|Timestamp
}

export class QualificationAssignment implements QualificationAssignmentDoc {
  id: string;
  archived: boolean;
  createdOn: any;
  lastUpdatedOn: any;
  createdBy: string;
  updatedBy: string;

  //base qualification
  card?: QualificationCard;
  title: string;
  uniqueId?: string;

  //assignment extensions
  assigned: boolean;
  employeeId: string;
  assignmentDate?: Timestamp;
  expirationDate?: Timestamp;
  expirationPeriod?: QualificationRefreshPeriod;
  firstName: string;
  lastName: string;
  jobTitle: string;
  qualificationDate: Timestamp;
  status: QualificationAssignmentStatus;
  totalProgress: number;
  type: QualificationType;
  userActive: boolean;
  userId: string;
  username: string;
  qualificationId: string;
  qualificationPath: string;
  initialQualificationDate: Timestamp;
  requirementGroupCompletionEvents: Record<string, QualificationAssignmentRequirementEvent>;
  allCompletionEvents?: QualificationAssignmentRequirementEvent[];
  requirementRefIds: string[];
  revocationPeriod?: number;
  revocationDate?: Timestamp;
  zone?: string;
  division?: string;
  location?: string;
  costCenter?: string;
  budgetGroup?: string;
  payId?: string;
  groupName: string;
  useReqsForExpiration?: boolean;
  restrictions?: string[];
  modifiedDateCycle?: boolean;

  qualification?: QualificationDoc;
  shouldRecalculateDates?: boolean;

  private withinRefresherExemption: boolean;
  private now: Date;
  private filteredRequirementGroups: QualificationAssignmentRequirementEvent[];

  constructor(assignment?: QualificationAssignmentDoc, options?: QualificationAssignmentOptions) {
    this.id = assignment ? assignment.id : null;
    this.archived = assignment ? assignment.archived : false;
    this.createdOn = assignment ? assignment.createdOn : new Date();
    this.lastUpdatedOn = assignment ? assignment.lastUpdatedOn : new Date();
    this.createdBy = assignment ? assignment.createdBy : null;
    this.updatedBy = assignment ? assignment.updatedBy : null;
    this.qualificationId = assignment ? assignment.qualificationId : null;
    this.qualificationPath = assignment ? assignment.qualificationPath : null;

    this.title = assignment ? assignment.title : null;
    this.uniqueId = assignment ? assignment.uniqueId : null;

    this.assigned = assignment.assigned;
    this.initialQualificationDate = assignment ? assignment.initialQualificationDate : null;
    this.assignmentDate = assignment.assignmentDate ? assignment.assignmentDate : null;
    this.expirationDate = assignment ? assignment.expirationDate : null;
    this.expirationPeriod = assignment ? assignment.expirationPeriod : null;
    this.qualificationDate = assignment ? assignment.qualificationDate : null;
    this.status = assignment ? assignment.status : null;
    this.firstName = assignment ? assignment.firstName : null;
    this.lastName = assignment ? assignment.lastName : null;
    this.jobTitle = assignment ? assignment.jobTitle : null;
    this.employeeId = assignment ? assignment.employeeId : null;
    this.userActive = assignment ? assignment.userActive : null;
    this.username = assignment ? assignment.username : null;
    this.zone = assignment ? assignment.zone : null;
    this.division = assignment ? assignment.division : null;
    this.userId = assignment ? assignment.userId : null;
    this.requirementGroupCompletionEvents = assignment ? assignment.requirementGroupCompletionEvents : null;
    this.allCompletionEvents = assignment ? assignment.allCompletionEvents : null;
    this.requirementRefIds = assignment ? assignment.requirementRefIds : null;
    this.type = assignment ? assignment.type : null;
    this.revocationPeriod = assignment ?  assignment.revocationPeriod : null;
    this.revocationDate = assignment ? assignment.revocationDate : null;
    this.location = assignment ? assignment.location : null;
    this.costCenter = assignment ? assignment.costCenter : null;
    this.budgetGroup = assignment ? assignment.budgetGroup : null;
    this.payId = assignment ? assignment.payId : null;
    this.groupName = assignment ? assignment.groupName : null;
    this.useReqsForExpiration = assignment ? assignment.useReqsForExpiration : null;
    this.modifiedDateCycle = assignment?.modifiedDateCycle || false;

    this.filteredRequirementGroups = [];

    if (options?.withQualification) {
      this.qualification = options.withQualification;
    }
    // Qualification must be present to run recalculations
    this.shouldRecalculateDates = (options?.withQualification && options.shouldRecalculateDates) || false;

    this.now = convertTimestamp(options?.simulatedNowDate) || new Date();
    this.now.setHours(0, 0, 0, 0);
  }

  static fromUserAndQualification(user: UserDocument, qualifcation: QualificationDoc) {
    return {
      qualificationId: qualifcation.id,
      title: qualifcation.title,
      uniqueId: qualifcation.uniqueId,
      firstName: user.firstName,
      lastName: user.lastName,
      jobTitle: user.title,
      employeeId: user.employeeId,
      userActive: user.active,
      username: user.username,
      userId: user.id,
      zone: user.zone,
      division: user.division,
      requirementRefIds: qualifcation.requirementRefIds,
      type: qualifcation.type,
      revocationPeriod: null,
      revocationDate: null,
      initialQualificationDate: null,
      archived: false,
      expirationDate: null,
      qualificationDate: null,
      location: user.location,
      costCenter: user.costCenter,
      budgetGroup: user.budgetGroup,
      payId: user.payId,
      groupName: user.groupName,
      useReqsForExpiration: qualifcation.useReqsForExpiration,
    } as QualificationAssignmentDoc;
  }

  // When using a Modified Qualification Date, the order of operations for state calculations is altered. To get correct values
  // we must first compute the expirationDate based on the manually set qualificationDate, then compute the full state as normal.
  public retrieveModifiedExpirationDateAndStatus() {
    if (!this.modifiedDateCycle) {
      throw new Error('QualificationAssignment must be on a modifiedDateCycle to compute modified expiration date and status.');
    }
    this.expirationDate = this.computeExpirationDate();
    this.computeQualificationState();
    return { expirationDate: this.expirationDate, status: this.status };
  }

  public fallsWithinRefresherTrainingExemption() {
    // - If no initialQualDate: true
    // - Else if qualification is expired: false
    // - Else (if not expired):
    //   - If initialQualDate but no qualDate, true
    //   - If initialQualDate === qualDate, true
    //   - Else: false
    return !this.initialQualificationDate || (
      (!!this.initialQualificationDate && !this.qualificationDate || // case where initial qualification set,
        // but qualification not set yet
        convertTimestamp(this.initialQualificationDate).toDateString() === convertTimestamp(this.qualificationDate).toDateString())
      && !(this.now >= convertTimestamp(this.expirationDate))) // within initial window
  }

  /**
   * Given requirementGroups from a QualificationAssignmentDocument, computes the overall
   * status of the assignment
   * @param requirementGroups
   * @returns a composite status value
   */
  private computeStatus() {
    /**
     * Decertified assignments must be manually updated
     */
    if ([QualificationAssignmentStatus.SUSPENDED, QualificationAssignmentStatus.RESTRICTED].includes(this.status)) {
      let endOfRevocation = convertTimestamp(this.revocationDate);
      endOfRevocation?.setHours(24 * this.revocationPeriod);
      if (endOfRevocation > this.now || !endOfRevocation) {
        return this.status;
      }
    }

    if ([QualificationAssignmentStatus.REVOKED].includes(this.status)) {
      return this.status;
    }
    else if (!!this.expirationDate && this.now > convertTimestamp(this.expirationDate)) {
      return QualificationAssignmentStatus.EXPIRED;
    } else {
      // If ALL groups Pass
      let allPass: boolean;

      if (this.withinRefresherExemption) {
        allPass = Object.values(this.requirementGroupCompletionEvents).every(({ status, isRefresherTraining }) => status === QualificationAssignmentRequirementEventStatus.PASS || isRefresherTraining);
      } else {
        allPass =  Object.values(this.requirementGroupCompletionEvents).every(({ status }) => status === QualificationAssignmentRequirementEventStatus.PASS);
      }
      const noneExpired = Object.values(this.requirementGroupCompletionEvents).every(({ expiresOn }) => {
        return !!expiresOn ? expiresOn.toDate() > this.now : true;
      });
      if (allPass && noneExpired) {
        if (this.status !== QualificationAssignmentStatus.DESIGNATED && this.type === QualificationType.CERTIFICATION) {
          return QualificationAssignmentStatus.CERTIFIED;
        } else {
          return QualificationAssignmentStatus.QUALIFIED;
        }
      } else if (this.status === QualificationAssignmentStatus.QUALIFIED && this.type === QualificationType.CERTIFICATION) {
        /**
         * The user has entries for each requirement group and was previously qualified, but now
         * they have some non-passing requirements, so they are suspended
         */
        return QualificationAssignmentStatus.SUSPENDED;
      } else if (this.status === QualificationAssignmentStatus.QUALIFIED) {
        /**
         * It was in a qualified status, but now something has expired, so it should be an expired status
         */
        return QualificationAssignmentStatus.INVALID;
      }
    }
    if (!this.initialQualificationDate) {
      return QualificationAssignmentStatus.STUDENT;
    } else {
      return QualificationAssignmentStatus.INVALID;
    }
  }

  /**
   * use the existing initialQualificationDate or find the earliest qualificationDate
   * from the requirementGroups
   */
  private computeInitialQualificationDate() {
    if (this.fallsWithinRefresherTrainingExemption()) {
      this.withinRefresherExemption = true;
    } else {
      this.withinRefresherExemption = false;
    }

    if (!!this.initialQualificationDate) {
      // Run the new timeline-based date calculations when requested, unless this assignment is on a modified date cycle.
      if (this.shouldRecalculateDates && !this.modifiedDateCycle) {
        const timeline = new QualificationTimeline(this.qualification, this.allCompletionEvents);

        // To make sure this first pass is a light touch, we will only use the new calculations if they
        // move the IQD back in time (e.g., a past completion was added which causes the user to have
        // been qualified earlier than they previously were).
        if (timeline.initialQualificationDate < convertTimestamp(this.initialQualificationDate)) {
          this.withinRefresherExemption = timeline.isWithinRefresherTrainingExemptionPeriod(this.now);

          if (this.qualificationDate
            && (this.withinRefresherExemption || timeline.initialQualificationDate > convertTimestamp(this.qualificationDate))
          ) {
            // If we're within the first cycle, or if the updated IQD is more recent than the current QD, update the QD to match it.
            // @ts-ignore (Date/Timestamp mismatch - should fix this eventually but it's everywhere in this file so today is not the day.)
            this.qualificationDate = timeline.initialQualificationDate;
          }
          return timeline.initialQualificationDate;

        } else {
          return this.initialQualificationDate;
        }
      } else {
        return this.initialQualificationDate;
      }
    }

    let allPass: boolean;
    if (this.withinRefresherExemption) {
      allPass = Object.values(this.requirementGroupCompletionEvents).every(({ status, isRefresherTraining }) => status === QualificationAssignmentRequirementEventStatus.PASS || isRefresherTraining);
    } else {
      allPass = Object.values(this.requirementGroupCompletionEvents).every(({ status }) => status === QualificationAssignmentRequirementEventStatus.PASS);
    }

    if (allPass) {
      // Return the *most recent* completedOn date out of all requirement groups
      return Object.values(this.requirementGroupCompletionEvents)
        .map(({ completedOn }) => completedOn)
        .sort(this.compareTimestamps)[0];
    }
    if (this.status === QualificationAssignmentStatus.DESIGNATED) {
      return this.now;
    }
    return null;
  }

  /**
   * use the existing qualificationDate or find the latest qualificationDate
   * from the requirementGroups
   */
  private computeQualificationDate() {
    if (!!this.qualificationDate && convertTimestamp(this.expirationDate) > this.now) {
      return this.qualificationDate;
    }

    if (this.modifiedDateCycle) {
      // Qualification has now expired while on its modifiedDateCycle, so we will return it to a standard cycle
      // starting from the recalculations that will occur next.
      this.modifiedDateCycle = false;
    }

    const noRequirementsHaveExpiration = Object.values(this.requirementGroupCompletionEvents).every(({ expiresOn, isRefresherTraining }) => {
      return expiresOn === null || (isRefresherTraining && this.withinRefresherExemption);
    });

    let allPass: boolean;
    if (this.withinRefresherExemption) {
      allPass = Object.values(this.requirementGroupCompletionEvents).every(({ status, isRefresherTraining }) => status === QualificationAssignmentRequirementEventStatus.PASS || isRefresherTraining);
    } else {
      allPass =  Object.values(this.requirementGroupCompletionEvents).every(({ status }) => status === QualificationAssignmentRequirementEventStatus.PASS);
    }

    if (noRequirementsHaveExpiration && allPass) {
      if (!!this.initialQualificationDate && !!this.qualificationDate && !!this.expirationDate) {
        // If the qualification was previously in a Qualified state, but has since expired, and its requirements have no expirations (or are exempt),
        // then set the qualification date to the last time at which it was still unexpired: the current expiration date.
        return this.expirationDate;
      }
      else if (!!this.initialQualificationDate && !this.qualificationDate && !this.expirationDate) { // in the process of first qualification
        let startingQualDate = Object.values(this.requirementGroupCompletionEvents)
          .map(({ completedOn }) => completedOn)
          .sort(this.compareTimestamps)[0];
        return this.recursiveFindQualificationDate(startingQualDate);
      }
    }

    if (allPass && Object.values(this.requirementGroupCompletionEvents)
      .every(({expiresOn}) => !!expiresOn ? convertTimestamp(expiresOn) > this.now : true)
    ) {
      if (!!this.initialQualificationDate && !this.qualificationDate) { // in the process of first qualification
        return Object.values(this.requirementGroupCompletionEvents)
          .map(({ completedOn }) => completedOn)
          .sort(this.compareTimestamps)[0];
      } else {
        return this.expirationDate;
      }
    } else {
      return this.qualificationDate;
    }
  }

  private recursiveFindQualificationDate(qualificationDate) {
    this.qualificationDate = qualificationDate;
    const rawExpirationDate = this.computeExpirationDate();
    let expirationDate = rawExpirationDate ? Timestamp.fromDate(rawExpirationDate) : null;
    if (convertTimestamp(expirationDate) < this.now && this.fallsWithinRefresherTrainingExemption()) {
      let tempQualification = this.computeExpirationDate();
      return this.recursiveFindQualificationDate(tempQualification);
    } else {
      return qualificationDate;
    }
  }

  private compareTimestamps(a, b) {
    return b.toMillis() - a.toMillis();
  }

  /**
   * if expires
   *  if user has refreshed all requirements
   *    expiration date is period + last refreshed date
   */
  private computeExpirationDate() {
    if (this.expirationPeriod && this.initialQualificationDate && this.qualificationDate) {

      const expirationDate = convertTimestamp(this.qualificationDate);
      switch (this.expirationPeriod.period) {
        case TimePeriod.DAY:
          expirationDate.setHours(24 * this.expirationPeriod.quantity);
          // Adding 8 hours to account for the conversion from UTC for all US Timezones
          expirationDate.setHours(8, 0, 0, 0);
          break;
        case TimePeriod.MONTH:
          expirationDate.setMonth(expirationDate.getMonth() + this.expirationPeriod.quantity);
          // Adding 8 hours to account for the conversion from UTC for all US Timezones
          expirationDate.setHours(8, 0, 0, 0);
          break;
        case TimePeriod.YEAR:
          // expires on a calendar year
          expirationDate.setFullYear(expirationDate.getFullYear() + this.expirationPeriod.quantity, 0, 1);
          // Adding 8 hours to account for the conversion from UTC for all US Timezones
          expirationDate.setHours(8, 0, 0, 0);
          break;
        case TimePeriod.CALENDAR_YEAR:
          const qualificationYear = this.qualificationDate.toDate().getFullYear();
          expirationDate.setFullYear(qualificationYear + this.expirationPeriod.quantity, 11, 31);
          expirationDate.setHours(8, 0, 0, 0);
          break;
        default:
          return null;
      }
      return expirationDate;
    }
    if (this.useReqsForExpiration && this.initialQualificationDate && this.qualificationDate) {
      const expirations = [];
      Object.values(this.requirementGroupCompletionEvents).forEach(
        event => {
          const expiresOn = convertTimestamp(event.expiresOn);
          if(expiresOn) {
            expirations.push(expiresOn);
          }
        }
      );
      const sortedExpirations = expirations.sort((a,b) => a - b);
      if(sortedExpirations[0]) return sortedExpirations[0];
      else return null;
    }
    return null;
  }

  /**
   * Removing from calculation any completion events that are not within their completion threshold
   * @private
   */
  private filterRequirementGroupCompletionEvents(): Record<string, QualificationAssignmentRequirementEvent> {
    const filteredRecord= Object.keys(this.requirementGroupCompletionEvents).reduce((acc, key) => {
      const item = this.requirementGroupCompletionEvents[key];
      if (item.isRefresherTraining) {
        if (item.completedOn < this.initialQualificationDate || this.status === QualificationAssignmentStatus.STUDENT) {
          // Store this filtered event so we can add it back after calculations are completed
          item.requirementGroupRefId = key;
          this.filteredRequirementGroups.push(item);
          return acc;
        }
      }
      if (QualificationAssignment.isWithinCompletionThreshold(item, this)) {
        acc[key] = item;

      } else {
        // Store this filtered event so we can add it back after calculations are completed
        item.requirementGroupRefId = key;
        this.filteredRequirementGroups.push(item);
      }
      return acc;
    }, {});

    return filteredRecord;
  }

  getIncompleteRequirementEventStub(requirementEvent: QualificationAssignmentRequirementEvent) {
    return {
      groupName: requirementEvent.groupName,
      requirementGroupRefId: requirementEvent.requirementGroupRefId,
      isRefresherTraining: requirementEvent.isRefresherTraining || false,
      status: QualificationAssignmentRequirementEventStatus.INCOMPLETE,
      // Persist these fields to make sure we filter this completion out in the same way going forward
      mustCompleteWithinXDays: requirementEvent.mustCompleteWithinXDays,
      completedOn: requirementEvent.completedOn,
      // Don't persist these fields because this completion is not valid anyway
      name: '',
      path: '',
      requirementRefId: ''
    }
  }

  private computeRestrictions() {
    if(this.type !== QualificationType.CERTIFICATION) return null
    let restrictions = [];
    Object.values(this.requirementGroupCompletionEvents).forEach(event => {
      if(event.restrictions){
        restrictions = [ ...restrictions, ...event.restrictions];
      }
    });
    return restrictions.filter((value, index, array) => array.indexOf(value) === index);
  }

  private computeQualificationState() {
    /**
     * @WARNING
     * execution order on the following is _very_ important!
     */
    this.requirementGroupCompletionEvents  = this.filterRequirementGroupCompletionEvents();
    //@ts-ignore  - mismatch on Timestamp vs Date
    this.initialQualificationDate = this.computeInitialQualificationDate();
    this.qualificationDate = this.computeQualificationDate();
    //@ts-ignore  - mismatch on Timestamp vs Date
    this.expirationDate = this.computeExpirationDate();
    this.status = this.computeStatus();
    this.restrictions = this.computeRestrictions();
  }

  public static isWithinCompletionThreshold(requirementCompletion: QualificationAssignmentRequirementEvent,
                                      qualificationAssignment: QualificationAssignmentDoc
  ) {
    if (!requirementCompletion.mustCompleteWithinXDays) return true;
    if (!qualificationAssignment.expirationDate) return true;
    if (convertTimestamp(qualificationAssignment.initialQualificationDate)?.toDateString() === convertTimestamp(qualificationAssignment.qualificationDate)?.toDateString()
      && (convertTimestamp(qualificationAssignment.qualificationDate) > convertTimestamp(requirementCompletion.completedOn))
    ) return true;
    let minimumDate = convertTimestamp(qualificationAssignment.expirationDate);
    minimumDate.setDate(minimumDate.getDate() - requirementCompletion.mustCompleteWithinXDays);
    minimumDate.setHours(8, 0, 0, 0);

    return minimumDate <= convertTimestamp(requirementCompletion.completedOn);
  }

  toFirestoreDocument(feedAssignment?: boolean) {
    if(!feedAssignment){
      this.computeQualificationState();
    }

    // Add back any requirement groups which were filtered out for the status calculations
    this.filteredRequirementGroups.forEach(group => {
      // Stub these groups as Incomplete since they were not factored into the calculations
      this.requirementGroupCompletionEvents[group.requirementGroupRefId] = this.getIncompleteRequirementEventStub(group);
    });

    return {
      assigned: !!this.assigned,
      archived: this.archived === undefined ? false : this.archived,
      createdBy: this.createdBy || null,
      createdOn: this.createdOn || null,
      employeeId: this.employeeId || null,
      firstName: this.firstName || null,
      jobTitle: this.jobTitle || null,
      lastName: this.lastName || null,
      groupName: this.groupName || null,
      location: this.location || null,
      costCenter: this.costCenter || null,
      budgetGroup: this.budgetGroup || null,
      payId: this.payId || null,
      lastUpdatedOn: this.lastUpdatedOn || null,
      qualificationId: this.qualificationId || null,
      qualificationPath: this.qualificationPath || null,
      requirementGroupCompletionEvents: this.requirementGroupCompletionEvents || null,
      allCompletionEvents: this.allCompletionEvents || null,
      requirementRefIds: this.requirementRefIds || null,
      title: this.title || null,
      uniqueId: this.uniqueId || null,
      updatedBy: this.updatedBy || null,
      userActive: this.userActive || null,
      userId: this.userId || null,
      username: this.username || null,
      zone: this.zone || null,
      division: this.division || null,
      id: this.id || null,
      type: this.type || null,
      useReqsForExpiration: this.useReqsForExpiration || null,
      restrictions: this.restrictions || [],
      modifiedDateCycle: this.modifiedDateCycle || false,

      expirationPeriod: this.expirationPeriod || null,
      initialQualificationDate: this.initialQualificationDate || null,
      qualificationDate: this.qualificationDate || null,
      expirationDate: this.expirationDate || null,
      status: this.status || null,
    } as QualificationAssignmentDoc;
  }
}

export class QualificationAssignmentSearchModel {
  doc: QualificationAssignmentDoc;
  id: string;
  tenantId: string;
  constructor(doc: QueryDocumentSnapshot<QualificationAssignmentDoc>, tenantId: string) {
    this.doc = doc.data();
    this.id = doc.id;
    this.tenantId = tenantId;
  }

  public toAlgolia() {
    const { employeeId, firstName, lastName, jobTitle, username, archived, lastUpdatedOn } = this.doc;
    const searchObj = {
      objectID: this.id,
      tenantId: this.tenantId,
      firstName,
      lastName,
      jobTitle,
      employeeId,
      username,
      archived,
      lastUpdatedOn: new Date(lastUpdatedOn?.valueOf()).getTime(),
    };
    return searchObj;
  }
}
