import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';
import { UserPermissions } from '../../../../../core/dto/userDoc';
import { environment } from 'src/environments/environment';
import { default as data } from '../../../../package.json';
import { Subscription } from 'rxjs';
import { tap, switchMap, filter } from 'rxjs/operators';
import { TenantService } from 'src/app/core/services/tenant.service';
import { Tenant } from '../../../../../core/models/tenant';
@Component({
  selector: 'app-nav-drawer',
  templateUrl: './nav-drawer.component.html',
  styleUrls: ['./nav-drawer.component.scss'],
})
export class NavDrawerComponent implements OnInit, OnDestroy {
  public expanded = false;
  public active: string;
  public userName = '';
  public version: string = data.version;
  public envName =
    environment.envName === 'production'
      ? ''
      : environment.envName.toUpperCase();
  public tenantName = '';
  public tenant: Tenant;
  public permissions: UserPermissions = {
    checklists: false,
    users: false,
    groups: false,
    reports: false,
    designateInstructors: false,
    testSupervisor: false,
    testAdminView: false,
    testAdminEdit: false,
    safetyAlertsView: false,
    safetyAlertsEdit: false,
    daModuleEdit: false,
    daModuleView: false,
    daModuleViewOrder: false,
    daModuleOrder: false,
    utModuleEdit: false,
    utModuleOrder: false,
    randomizerModuleEdit: false,
    randomizerModuleView: false,
    datEView: false,
    datEEdit: false,
    manageMobileQualifications: false,
    assignQualifications: false,
    qualificationsModule: {
      edit: false,
      view: false,
    },
    onboardingModule: {
      edit: false,
      view: false,
    }
  };
  private subs: Subscription[] = [];
  public canCreateTenant = false;
  public showLimitedView = false;
  public isSuperuser = false;
  public activeImpersonationSession = false;
  constructor(
    private userService: UserService,
    private tenantService: TenantService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.subs.push(
      this.userService.user
        .pipe(
          tap((user) => {
            if (user && user.permissions) {
              this.permissions = user.permissions;
              this.userName = user.username;
              this.canCreateTenant = user?.canCreateTenant;

              this.activeImpersonationSession = !!this.userService.impersonatedUser;
            }
          }),
          switchMap(() => {
            return this.tenantService.tenant;
          }),
          filter((tenant) => !!tenant),
        )
        .subscribe((tenant) => {
          this.tenant = tenant;
          this.tenantName = tenant.name;
        }),
    );
    this.checkPermissions();
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  unimpersonate() {
    this.userService.unimpersonate();
    this.router.navigate(['/spark-tools']);
  }

  logout() {
    this.router.navigate(['logout']);
  }

  checkPermissions() {
    const user = this.userService.currentUser;
    const onboardingView = user.permissions?.onboardingModule?.view;
    const daModuleEdit = user.permissions?.daModuleEdit;
    const daModuleView = user.permissions?.daModuleView;
    const daModuleOrder = user.permissions?.daModuleOrder;
    const daModuleViewOrder = user.permissions?.daModuleViewOrder;

    this.showLimitedView = onboardingView && daModuleEdit && !daModuleView && !daModuleOrder && !daModuleViewOrder;
    this.isSuperuser = this.userService.hasSuperuserPrivileges;
  }
}
