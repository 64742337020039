import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import firebase from "firebase/compat/app";
import 'firebase/firestore';
import 'firebase/auth';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { createUserObjFromUserRowRecord, UserRow } from '../../../../../core/dto/userRowRecord';
import { UserObj } from '../../../../../core/models/user';
import { Group } from '../../../../../core/models/group';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private READ_ONLY_TENANTS = ['kcs'];
  private usersCollectionObservable: Observable<AngularFirestoreCollection<UserObj>>;

  private _authState = new BehaviorSubject<firebase.User>(null);
  public readonly authState = this._authState.asObservable();
  private _user = new BehaviorSubject<UserObj>(null);
  public readonly user = this._user.asObservable();
  private _userSql = new BehaviorSubject<any>(null);
  public readonly userSql = this._userSql.asObservable();

  private _users = new BehaviorSubject<UserObj[]>(null);
  public readonly users = this._users.asObservable();

  constructor(private afs: AngularFirestore, private http: HttpClient) {
    this.usersCollectionObservable = this.user.pipe(
      map((user) => {
        if (user) {
          return this.getUsersCollectionForTenant(user.tenantId);
        }
        return null;
      }),
    );

    this.users = this.usersCollectionObservable.pipe(
      switchMap((usersCollection) => (usersCollection ? usersCollection.snapshotChanges() : of([]))),
      map((actions) => {
        return actions.map((action) => {
          const data = action.payload.doc.data();
          const id = action.payload.doc.id;
          return { ...data, id };
        });
      }),
    );
  }

  public isReadOnlyTenant() {
    return this.READ_ONLY_TENANTS.includes(this.currentUser.tenantId);
  }

  public usersFiltered(
    limit: number,
    offset: number,
    groupName: string,
    budgetGroup: string,
    division: string,
    zone: string,
    payId: string,
    location: string,
    jobTitle: string,
  ): Promise<{ data: UserObj[], totalCount: number}> {
    const sqlId = this.currentUser.sqlId;

    const url = `${environment.apiV2}/users/filtered`;
    let queryParams = new HttpParams({
      fromObject: {
        userId: sqlId.toString(),
        limit: limit.toString(),
        page: offset.toString(),
      },
    });

    if (groupName) {
      queryParams = queryParams.append('groupName', groupName);
    }
    if (budgetGroup) {
      queryParams = queryParams.append('budgetGroup', budgetGroup);
    }
    if (division) {
      queryParams = queryParams.append('division', division);
    }
    if (zone) {
      queryParams = queryParams.append('zone', zone);
    }
    if (payId) {
      queryParams = queryParams.append('payId', payId);
    }
    if (location) {
      queryParams = queryParams.append('location', location);
    }
    if (jobTitle) {
      queryParams = queryParams.append('jobTitle', jobTitle);
    }

    return this.http
      .get<{ data: UserRow[]; totalCount: number }>(url, { params: queryParams })
      .pipe(
        map(({data, totalCount}) => {
          return {data: data.map((user) => createUserObjFromUserRowRecord(user)), totalCount};
        }),
      )
      .toPromise();
  }

  private getGroupsCollectionForTenant(tenantId: string): AngularFirestoreCollection<Group> {
    return this.afs.collection('tenants').doc(tenantId).collection('groups');
  }

  private getUsersCollectionForTenant(tenantId: string): AngularFirestoreCollection<UserObj> {
    return this.afs.collection('users', (ref) => ref.where('tenantId', '==', tenantId));
  }

  get currentUser() {
    return this._user.getValue();
  }

  public async createUser(id: string, newUser): Promise<any> {
    const now = firebase.firestore.Timestamp.now();
    const user = this.currentUser;

    if (!user) {
      throw new Error('Must be logged in to create a user.');
    }

    const data = {
      archived: false,
      createdBy: id,
      createdOn: now,
      lastUpdatedOn: now,
      id,
      tenantId: user.tenantId,
      ...newUser,
    };
    return this.http.post(`${environment.apiV1}/users`, { user: data }).toPromise();
  }

  public appendEmailToUsername(username: string) {
    return `psuedoemail+${username}@railtasker.com`;
  }

  public getUserByUsername(username: string): Observable<any> {
    // const tenantId = this._user.getValue().tenantId;
    return this.afs.collection<UserObj>(`users`, (ref) => ref.where('username', '==', username)).valueChanges();
  }

  public async checkForUserEmail(username: string) {
    return new Promise((resolve, reject) => {
      this.getUserByUsername(username)
        .pipe(take(1))
        .subscribe((users: UserObj[]) => {
          if (users[0].email) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
    });
  }

  public async checkIfUsernameUnique(username: string) {
    return new Promise((resolve, reject) => {
      this.getUserByUsername(username)
        .pipe(take(1))
        .subscribe((users: UserObj[]) => {
          if (!users || !users.length) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
    });
  }

  public async checkIfEmployeeIdUnique(employeeId: string) {
    return new Promise(async (resolve, reject) => {
      const user = this.currentUser;
      if (!user) {
        throw new Error('Must be logged in to update a user.');
      }
      const userSnap = await this.afs
        .collection<UserObj>(`users`, (ref) => ref.where('employeeId', '==', employeeId).where('tenantId', '==', user.tenantId))
        .get()
        .toPromise();
      if (userSnap.empty) {
        return resolve(true);
      }
      const users = userSnap.docs.map((user) => ({
        ...(user.data() as UserObj),
      }));
      if (users && users.length > 0) {
        return resolve(false);
      } else {
        return resolve(true);
      }
    });
  }

  // Fetch user from apiv2 by firebase auth uid
  public getUserById(firestoreId: string): Observable<UserObj> {
    const url = `${environment.apiV2}/users/by-firestore-id/${firestoreId}`;
    return this.http.get<UserRow>(url).pipe(
      map((userRow) => {
        return createUserObjFromUserRowRecord(userRow);
      }),
    );
  }

  // Set this.userSql by fetching from api v2
  public async getUserByIdSql(firebaseId: string): Promise<void> {
    const url = environment.apiV2.concat(`/users/by-firestore-id/${firebaseId}`);
    const userSql = await this.http.get(url).toPromise();
    this._userSql.next(userSql);
  }

  /**
   * Fetches a single user from firestore, returning a UserObj
   *
   * @param id ID of the user to fetch
   */
  public async fetchUserById(id: string): Promise<UserObj> {
    const usersCollection = this.afs.collection<UserObj>('users');
    const userSnapShot = await usersCollection.doc(id).ref.get();
    return new UserObj({ ...userSnapShot.data(), id });
  }

  /**
   * Fetches a single user from firestore copying properties that match to a UserObj.
   *
   * @param id ID of the user to fetch
   */
  public async fetchUserByUsername(tenantId: string, username: string): Promise<UserObj> {
    const usersCollection = this.afs.collection<UserObj>('users', (ref) =>
      ref.where('username', '==', username).where('tenantId', '==', tenantId).where('archived', '==', false),
    );
    const userSnapShot = await usersCollection.get().toPromise();
    if (userSnapShot.docs.length < 1) {
      return null;
    }
    const userObj: UserObj = new UserObj();
    Object.assign(userObj, userSnapShot.docs[0].data());
    return userObj;
  }

  public async userRecordExists(id: string): Promise<boolean> {
    const usersCollection = this.afs.collection<UserObj>('users');
    const docRef = usersCollection.doc(id).ref;
    return docRef != null;
  }

  public updateUser(updatedUser: Partial<UserObj>, formerGroupId?: string): Promise<any> {
    const user = this.currentUser;
    if (!user) {
      throw new Error('Must be logged in to update a user.');
    }
    updatedUser.lastUpdatedOn = new Date();
    updatedUser.updatedBy = user.id;
    return this.http.put(`${environment.apiV1}/users`, { user: updatedUser }).toPromise();
  }

  /**
   * Sets a property on a firestore User document.
   *
   * WARNING: Does not validate that property is part of User model or DTO.
   * @param {string} userDocId
   * @param {*} props
   * @returns {Promise<void>}
   * @memberof UserService
   */
  public async setUserProperty(userDocId: string, props: any): Promise<void> {
    const now = firebase.firestore.Timestamp.now();
    return this.afs.doc(`users/${userDocId}`).ref.update({
      ...props,
      lastUpdatedOn: now,
      updatedBy: this.currentUser.id,
    });
  }

  public setUserAndTenantId(user: UserObj) {
    if (!SecureContext.initialized) {
      SecureContext.init(user);

      if (this.impersonatedUser) {
        this._user.next(this.impersonatedUser);
        return;
      }
    }

    this._user.next(user);
  }

  get hasSuperuserPrivileges() {
    return SecureContext.isSuperuser(this.currentUser);
  }

  get impersonatingSuperuser(): UserObj|null {
    return SecureContext.impersonatingUser;
  }

  get impersonatedUser(): UserObj|null {
    return SecureContext.impersonatedUser;
  }

  public impersonate(user: UserObj) {
    if (SecureContext.impersonate(user, this.currentUser)) {
      this._user.next(user);
    }
  }

  public unimpersonate() {
    if (this.impersonatingSuperuser) {
      const originalUser = this.impersonatingSuperuser;
      SecureContext.unimpersonate();
      this._user.next(originalUser);
    }
  }

  public clearUser() {
    this.unimpersonate();
    this._user.next(null);
  }
}

// Secure context for Spark Superuser impersonation operations.
// NOTE: The context is secure because it only exists within the confines the UserService module. This ensures third-parties
// cannot execute unsupervised write operations on the `impersonatingUser` or `impersonatedUser` values. Utility functions
// are exposed through the UserService interface to read values and to securely impersonate and unimpersonate.
// IMPORTANT: *NEVER* export the SecureContext, and *NEVER* expose direct write operations on its values through UserService.
const SecureContext = {
  initialized: false,
  impersonatingUser: null,
  impersonatedUser: null,

  init(currentUser: UserObj) {
    this.initialized = true;

    if (this.isSuperuser(currentUser)) {
      let impersonatingUserData: string,
          userToImpersonateData: string;
      if ((impersonatingUserData = sessionStorage.getItem('spark-impersonating-user'))
        && (userToImpersonateData = sessionStorage.getItem('spark-impersonated-user'))
      ) {
        this.impersonate(
          JSON.parse(userToImpersonateData) as UserObj,
          JSON.parse(impersonatingUserData) as UserObj
        );
      }
    }
  },

  impersonate(userToImpersonate: UserObj, impersonatingUser: UserObj) {
    // If we already have an impersonatingUser, do not allow that to be overwritten.
    // We must always be able to revert back to the original user.
    impersonatingUser = this.impersonatingUser || impersonatingUser;

    if (!this.isSuperuser(impersonatingUser)) { throw new Error('access_denied'); }
    if (!userToImpersonate) { throw new Error('no_user_to_impersonate'); }

    this.impersonatingUser = impersonatingUser;
    sessionStorage.setItem('spark-impersonating-user', JSON.stringify(impersonatingUser));
    this.impersonatedUser = userToImpersonate;
    sessionStorage.setItem('spark-impersonated-user', JSON.stringify(userToImpersonate));

    return true;
  },

  unimpersonate() {
    this.impersonatingUser = null;
    sessionStorage.removeItem('spark-impersonating-user');
    this.impersonatedUser = null;
    sessionStorage.removeItem('spark-impersonated-user');
  },

  isSuperuser(user?: UserObj): boolean {
    return !!this.impersonatingUser || (
      user?.tenantId === 'superuser'
        && user?.groupId === 'superuser'
        && user?.permissions?.superuser === true
    );
  }
};
