export enum QualificationType {
  QUALIFICATION = 'qualification',
  CERTIFICATION = 'certification',
  ALL = 'all',
  TERRITORY = 'territory',
}

export enum QualificationsStatus {
  active = 'active',
  inactive = 'inactive',
  all = 'all',
}
