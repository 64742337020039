import { TenantDocument } from '../dto/tenantDoc';

export class Tenant implements TenantDocument {
  name: string;
  apiKey: string;
  qualifiedPersonAsDesignatedInstructor: boolean;
  id: string;
  onboardingModule?: boolean = false; // Show Onboarding module
  operationsModule?: boolean = false; // Show Operations module
  safetyAlertsModule?: boolean = false; // Show Safety Alerts module
  daModule?: boolean = false; // Show Drug & Alcohol Module
  qualificationsModule?: boolean = false; // Qualifications + Certifications module
  unscheduledTestsModule?: boolean = false; // Show Unscheduled Tests
  randomizerModule?: boolean = false; // Show Randomizer
  datEModule?: boolean = false; // Show DATe module
  territoryQualsEnabled?: boolean = false; // Show Territory Qualifications Tab in Quals module
  ssoLogout: boolean = false;
  jobTitles: string[] = [];
  jobDuties: string[] = [];
  budgetGroups: string[] = [];
  costCenters: string[] = [];
  divisions: string[] = [];
  zones: string[] = [];
  payIds: string[] = [];
  followUpDetails: string[] = [];
  formFoxEnabled?: boolean = false;
  postalCode?: string = null;
  city?: string = null;
  state?: string = null;
  address?: string = null;
  contactName?: string = null;
  contactNumber?: string = null;
  testGroups?: string[] = [];
  selfReportingEnabled?: boolean = false;
  isPeopleTasker?: boolean = false;

  constructor(data: any) {
    Object.assign(this, data);
  }
}
