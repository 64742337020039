import { QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { ExternalRequirementDoc } from '../../dto/qualifications';
import { ExternalRequirementType } from '../../enums/qualifications/externalRequirementType';

export class ExternalRequirement implements ExternalRequirementDoc {
  id: string;
  archived: boolean;
  createdOn: Date;
  lastUpdatedOn: Date;
  createdBy: string;
  updatedBy: string;
  type: ExternalRequirementType;
  restrictions?: string[];

  name: string;
  apiId: string;
  description: string;
  uniqueId: string;
  mustCompleteWithinXDays?: number;

  constructor(externalRequirement?: ExternalRequirement | ExternalRequirementDoc) {
    this.id = externalRequirement ? externalRequirement.id : null;
    this.archived = externalRequirement ? externalRequirement.archived : false;
    // this.createdOn = externalRequirement ? externalRequirement.createdOn : new Date();
    // this.lastUpdatedOn = externalRequirement ? externalRequirement.lastUpdatedOn : new Date();
    this.createdBy = externalRequirement ? externalRequirement.createdBy : null;
    this.updatedBy = externalRequirement ? externalRequirement.updatedBy : null;
    this.name = externalRequirement ? externalRequirement.name : null;
    this.description = externalRequirement ? externalRequirement.description : null;
    this.uniqueId = externalRequirement ? externalRequirement.uniqueId : null;
    this.mustCompleteWithinXDays = externalRequirement ? externalRequirement.mustCompleteWithinXDays : null;
    this.apiId = externalRequirement ? externalRequirement.apiId : null;
    this.type = externalRequirement ? externalRequirement.type ? externalRequirement.type : ExternalRequirementType.OTHER : null;
  }
}

export class ExternalRequirementSearchModel {
  doc: ExternalRequirementDoc;
  id: string;
  tenantId: string;
  constructor(doc: QueryDocumentSnapshot<ExternalRequirementDoc>, tenantId: string) {
    this.doc = doc.data();
    this.id = doc.id;
    this.tenantId = tenantId;
  }

  public toAlgolia() {
    const { archived, uniqueId, name, description, lastUpdatedOn, apiId } = this.doc;
    const searchObj = {
      objectID: this.id,
      tenantId: this.tenantId,
      apiId,
      name,
      archived,
      uniqueId,
      description,
      lastUpdatedOn: new Date(lastUpdatedOn?.valueOf()).getTime(),
    };
    return searchObj;
  }
}
