export function convertTimestamp(date: Date | { toDate: () => string } | string | number | { seconds: number }): Date {
  if (date) {
    if (typeof date === 'string') {
      return new Date(date);
    }
    if (typeof date === 'number') {
      return new Date(date);
    }
    if ('toDate' in date) {
      return new Date(date.toDate());
    }
    if (date instanceof Date) {
      return new Date(date);
    }
    if (date.seconds) {
      return new Date(date.seconds * 1000);
    }
  }
  
  return undefined;
}
