import firebase from "firebase/compat/app";
import Timestamp = firebase.firestore.Timestamp;

export enum QualificationAssignmentAuditEventTrigger {
    AUTO = 'AUTO',
    ADMIN = 'ADMIN',
    ASSIGN = 'ASSIGN',
    UPLOAD = 'UPLOAD',
}

export class QualificationAssignmentAuditEvent {
    actionDate: Date | Timestamp;
    adminId?: string;
    expirationDate: Date | Timestamp;
    files?: any[];
    initialQualificationDate: Date | Timestamp;
    qualificationDate: Date | Timestamp;
    remarks?: string;
    status: string;
    trigger: QualificationAssignmentAuditEventTrigger;
}

export class QualificationAssignmentAuditEntry implements QualificationAssignmentAuditEvent {

    actionDate: Date | Timestamp;
    adminId?: string;
    documentId: string;
    expirationDate: Date | Timestamp;
    files: any[];
    initialQualificationDate: Date | Timestamp;
    qualificationDate: Date | Timestamp;
    remarks: string;
    status: string;
    trigger: QualificationAssignmentAuditEventTrigger;
    
    updatedBy: string;

    constructor(auditEvent: QualificationAssignmentAuditEvent | QualificationAssignmentAuditEntry, documentId: string) {
        this.actionDate = auditEvent.actionDate;
        this.adminId = auditEvent.adminId || null;
        this.documentId = documentId;
        this.expirationDate = auditEvent.expirationDate;
        this.files = auditEvent.files || [];
        this.initialQualificationDate = auditEvent.initialQualificationDate;
        this.qualificationDate = auditEvent.qualificationDate;
        this.remarks = auditEvent.remarks || '';
        this.status = auditEvent.status;
        this.trigger = auditEvent.trigger;
        this.updatedBy = '-';
    }

}
