import {
  QualificationDoc,
  QualificationRefreshPeriod,
  QualificationAssignmentRequirementEvent,
  RequirementGroupDoc
} from '../../dto';
import { TimePeriod } from '../../enums/qualifications';
import { DateTime, Duration, Interval } from 'luxon';
import firebase from 'firebase/compat/app';
type Timestamp = firebase.firestore.Timestamp;

export class QualificationTimeline {
  expirationPeriod: QualificationRefreshPeriod;

  allRequirementGroups: { [id: string]: TLRequirementGroup };
  standardGroups: TLRequirementGroup[];
  standardGroupQualifiedPeriods: Interval[];
  refresherTrainingGroups: TLRequirementGroup[];

  refresherTrainingExemptionPeriod: Interval;
  qualifiedPeriods: Interval[];

  // Internal version kept as DateTime for further calculations
  #initialQualificationDate: DateTime;
  // Public version converts to Date to ensure we aren't accidentally passing DateTimes around
  public get initialQualificationDate(): Date {
    return this.#initialQualificationDate?.toJSDate();
  }

  constructor(qualification: QualificationDoc, allCompletionEvents: QualificationAssignmentRequirementEvent[]) {
    this.expirationPeriod = qualification.expirationPeriod;

    this.allRequirementGroups = {};
    this.standardGroups = [];
    this.refresherTrainingGroups = [];

    for (const groupDoc of qualification.requirementGroups) {
      const group = new TLRequirementGroup(groupDoc);
      this.allRequirementGroups[group.id] = group;

      const groupCategory = group.isRefresherTrainingGroup
        ? this.refresherTrainingGroups
        : this.standardGroups;
      groupCategory.push(group);
    }

    for (const completionEvent of allCompletionEvents) {
      this.allRequirementGroups[completionEvent.requirementGroupRefId]
        ?.addQualifyingEvent(new TLQualifyingEvent(completionEvent.completedOn, completionEvent.expiresOn));
    }

    this.computeInitialQualificationDate();
    if (this.initialQualificationDate) {
      this.computeRefresherTrainingExemptionPeriod();
    }
  }

  public isWithinRefresherTrainingExemptionPeriod(date: Date) {
    return this.refresherTrainingExemptionPeriod.contains(DateTime.fromJSDate(date));
  }

  computeInitialQualificationDate() {
    // Start the IQD with the first standard group's qualified periods, then attenuate through the remaining standard groups.
    const qualifiedPeriods = this.attenuateQualifiedPeriods(this.standardGroups[0]?.qualifiedPeriods, this.standardGroups.slice(1));
    // IQD is the start date of the earliest interval that overlaps *all* standard groups.
    this.#initialQualificationDate = qualifiedPeriods[0]?.start || null;
    // Save these calculations so we don't need to repeat them when factoring in other groups.
    this.standardGroupQualifiedPeriods = qualifiedPeriods;
  }

  computeRefresherTrainingExemptionPeriod() {
    // Can't compute refresherTrainingExemptionPeriod until an initialQualificationDate has been set.
    if (!this.#initialQualificationDate) { return; }

    // Refresher training exemption goes from IQD until the qualification's first expiration date following the IQD.
    this.refresherTrainingExemptionPeriod = Interval.fromDateTimes(
      this.#initialQualificationDate,
      this.computeExpirationFrom(this.#initialQualificationDate)
    );
    this.refresherTrainingGroups.forEach(group => group.setRefresherTrainingExemptionPeriod(this.refresherTrainingExemptionPeriod));
  }

  /*
   * Given a set of starting Intervals and a list of RequirementGroups, return a new set of Intervals
   * composed of all segments which appeared in both the starting set *and* every single RequirementGroup's
   * set of qualified period Intervals. This function is explictly an attenuator: it can only result in
   * a *narrower* set of Intervals than those passed in (or an equal set if all Intervals are identical).
   *
   * This function is idempotent: no matter which of the above inputs are used as the starting Interval or
   * which are passed in via requirement groups, the output will always be the same, and will always be sorted
   * from earliest to latest.
   */
  attenuateQualifiedPeriods(startingIntervals: Interval[], requirementGroups: TLRequirementGroup[] = []) {
    // If startingIntervals is null or empty then there are no qualified periods.
    if (!startingIntervals?.length) { return []; }

    let currentIntervals = startingIntervals;
    for (const group of requirementGroups) {
      // Get all qualified periods that *do not* overlap between the current intervals and this group.
      const xor = Interval.xor([ ...currentIntervals, ...group.qualifiedPeriods ]);

      // Use the xor to calculate all periods that *do* overlap.
      currentIntervals = Interval.merge( // Merge results into a single, sorted, minimal set of intervals.
        currentIntervals.flatMap(it => it.difference(...xor))
      );
    }
    return currentIntervals;
  }

  computeExpirationFrom(date: DateTime) {
    if (!this.expirationPeriod) {
      return date.plus({ 'years': 500 }); // Give non-expiring qualifications 500 years
    }

    if ([TimePeriod.DAY, TimePeriod.MONTH, TimePeriod.QUARTER].includes(this.expirationPeriod.period)) {
      return date.plus({ [this.expirationPeriod.period]: this.expirationPeriod.quantity });

    } else if (this.expirationPeriod.period === TimePeriod.YEAR) {
      return date
        .plus({ [TimePeriod.YEAR]: this.expirationPeriod.quantity }) // E.g. If period is 1 Year, return the start of the next year.
        .startOf(TimePeriod.YEAR);

    } else if (this.expirationPeriod.period === TimePeriod.CALENDAR_YEAR) {
      return date
        .plus({ [TimePeriod.YEAR]: this.expirationPeriod.quantity }) // E.g. If period is 1 Year, return the end of the next year.
        .endOf(TimePeriod.YEAR);

    } else {
      console.error('unknown_expiration_period', { period: this.expirationPeriod.period });
      throw new Error('unknown_expiration_period');
    }
  }
}

class TLRequirementGroup {
  groupDoc: RequirementGroupDoc;
  qualifyingEvents: TLQualifyingEvent[];
  refresherTrainingExemptionPeriod: Interval;

  get id() {
    return this.groupDoc.id;
  }

  get name() {
    return this.groupDoc.name;
  }

  get isRefresherTrainingGroup() {
    return !!this.groupDoc.isRefresherTraining;
  }

  /*
   * Kept private so we can guarantee safe handling through the public `qualifiedPeriods` getter below.
   * Refresher training groups should always return merges of their exemption period and their qualifiedPeriods,
   * but we don't want to *actually* merge those Intervals because they need to be able to change independently as
   * new qualifying events are added over time. Keeping a private copy simplifies and protects this logic.
   */
  #qualifiedPeriods: Interval[];
  get qualifiedPeriods(): Interval[] {
    if (!this.isRefresherTrainingGroup) {
      return this.#qualifiedPeriods; // Nothing special for non-refresher groups.

    } else {
      // Refresher groups cannot return their qualifiedPeriods unless their exemption period has been set.
      if (!this.refresherTrainingExemptionPeriod) {
        throw new Error('cannot_access_refresher_group_qualified_periods_before_exemption_period_is_set');
      }
      // Merge in exemption periods as free "qualified" time for these groups.
      return Interval.merge([...this.#qualifiedPeriods, this.refresherTrainingExemptionPeriod]);
    }
  }

  constructor(groupDoc: RequirementGroupDoc) {
    this.groupDoc = groupDoc;
    this.qualifyingEvents = [];
    this.#qualifiedPeriods = [];
  }

  addQualifyingEvent(qualifyingEvent: TLQualifyingEvent) {
    this.qualifyingEvents.push(qualifyingEvent);
    this.#qualifiedPeriods = Interval.merge([
      ...this.#qualifiedPeriods,
      qualifyingEvent.qualifiedPeriod
    ]);
  }

  setRefresherTrainingExemptionPeriod(period: Interval) {
    this.refresherTrainingExemptionPeriod = period;
  }

  isQualifiedOn(date: Date): boolean {
    return this.qualifiedPeriods.some(it => it.contains(DateTime.fromJSDate(date)));
  }
}

class TLQualifyingEvent {
  completedOn: Date;
  expiresOn: Date;
  qualifiedPeriod: Interval;

  constructor(completedOn: Date|Timestamp, expiresOn?: Date|Timestamp) {
    this.completedOn = completedOn instanceof Date ? completedOn : completedOn.toDate();
    this.expiresOn = expiresOn ? (expiresOn instanceof Date ? expiresOn : expiresOn.toDate()) : null;
    this.qualifiedPeriod = !this.expiresOn
      ? Interval.after(this.completedOn, Duration.fromObject({ years: 500 })) // Give non-expiring reqs 500 years
      : Interval.fromDateTimes(this.completedOn, this.expiresOn);
  }
}
